
















































import Vue from 'vue'

//define props
const props = {
  marker: Object, //marker obj
  markerEditMode: Boolean
}

export default Vue.extend({
  name: 'MapMarkerListItem',
  props,
  data: () => ({
    dialog: false
  }),
  methods: {
    onEditMarker() {
      this.$emit('onEditMarker', this.marker)
    },
    onDeleteMarker() {
      this.dialog = false
      this.$emit('onDeleteMarker', this.marker)
    }
  }
})
