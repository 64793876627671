export enum EMapLayerType {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export interface IHeadingItem {
  isHeading: boolean
  text: string
}

export interface ISiteMapLayerAssociation {
  enabled: boolean
  id: number
  map_layer_id: number
  site_id: number
}

export interface IMapLayer {
  id: number
  api_key: string
  attribution: string
  created_at: string
  updated_at: string
  max_zoom: number
  name: string
  offline: boolean
  path: string
  url: string
  seeded: boolean
  format: string
  path_template: string
  fallback: boolean
}
