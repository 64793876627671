<template>
  <base-map
    :ref="`baseMap-${this.name}`"
    :center="mapCenter || siteCenter"
    :zoom="siteZoom"
    @update:zoom="$emit('update:zoom', $event)"
    @click="
      $emit('click', $event)
      onMapClick()
    "
    editable
    :name="name"
    :searchBar="searchBar"
    :measureEnabled="measureEnabled"
    :disabled="disabled"
    :layerSwitch="!exporting"
    :zoomControl="!exporting"
    class="map"
  >
    <l-marker
      class="marker"
      :lat-lng="mapMarkerLatLng"
      @dragend="onLatLngChange"
      @move="updateLatLng"
      draggable
    >
      <l-icon class="container" :iconSize="[40, 40]">
        <MapMarkerSVG :colour="this.markerColour" :highlight="true" />
        <div class="centered">
          {{ markerNumberText }}
        </div>
      </l-icon>
    </l-marker>
  </base-map>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import BaseMap from '@/components/Map/BaseMap.vue'
import { LMarker, LIcon } from 'vue2-leaflet'

const MapMarkerSVG = () =>
  import('@/components/Map/MapSiteMarkers/MapMarkerSVG.vue')

const props = {
  edit: { type: Boolean, default: false },
  marker: {
    type: Object
  },
  shape: {
    type: Array
  },
  zoneEditMode: {
    type: Boolean,
    default: false
  },
  searchBar: {
    type: Boolean,
    default: true
  },
  mapCenter: {
    type: Array
  },
  name: String,
  disabled: {
    type: Boolean,
    default: false
  },
  measureEnabled: {
    type: Boolean,
    default: true
  },
  active: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'MapMarkersDialogMap',
  components: {
    BaseMap,
    LMarker,
    LIcon,
    MapMarkerSVG
  },
  props,
  model: {
    prop: 'shape',
    event: 'change'
  },

  data: () => ({
    viewShedAreas: [],
    rfPerfGrid: [],
    accuracyLabels: ['Very High', 'High', 'Better'],
    lastDragCenter: null,
    createMarkerLatLng: [0, 0],
    markerColour: '',
    markerNumber: ''
  }),
  mounted() {
    this.$bus.$on('changeMarkerColour', this.switchMarkerColour)
    this.$bus.$on('changeMarkerNumber', this.switchMarkerNumber)
    if (this.edit) {
      this.markerColour = this.marker.colour
    }
  },
  computed: {
    ...mapGetters('sites', ['siteZoom', 'siteCenter']),
    ...mapGetters('sentries', ['activeSentryId']),
    ...mapState('site_markers', ['site_markers']),
    ...mapGetters('maps', ['exporting']),
    ...mapState('selection', {
      activeSentries: 'activeSentries',
      activeSensors: 'activeSensors',
      activeGroups: 'activeGroups',
      selectedCamera: 'activeCamera'
    }),
    getMarkerColour() {
      return this.marker?.colour || ''
    },
    markerNumberText() {
      return this.marker?.number || this.markerNumber
    },
    iconSize() {
      return [40, 40]
    },
    mapMarkerLatLng() {
      if (this?.marker?.id) {
        return [this.marker.lat, this.marker.lng]
      }
      const [lat, lng] = this.siteCenter
      //generate new marker away from existing markers
      return [
        lat + Math.floor(Math.random() * 0.01),
        lng + Math.floor(Math.random() * 0.01)
      ]
    }
  },
  methods: {
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    ...mapActions('plans', {
      activateInstallation: 'ACTIVATE_INSTALLATION'
    }),
    ...mapActions('selection', [
      'clearSentries',
      'clearSensors',
      'clearGroups',
      'setActiveCamera'
    ]),
    switchMarkerColour(v) {
      this.markerColour = v
    },
    switchMarkerNumber(v) {
      this.markerNumber = v
    },

    updateLatLng({ latlng }) {
      this.createMarkerLatLng[0] = latlng.lat
      this.createMarkerLatLng[1] = latlng.lng
    },
    onLatLngChange() {
      this.$emit('moveMarker', this.createMarkerLatLng)
    },
    async onMapClick() {
      await this.clearSensors()
      await this.setActiveCamera(null)
      await this.clearGroups()
      await this.clearSentries()
    },

    eventToLatLng(e) {
      return this.$refs[`baseMap-${this.name}`].mapObject.mouseEventToLatLng(e)
    },

    invalidateMap() {
      this.$refs[`baseMap-${this.name}`] &&
        this.$refs[`baseMap-${this.name}`].invalidateSize()
    }
  },

  watch: {
    active(v) {
      this.invalidateMap()
    }
  }
}
</script>
<style scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.container {
  position: relative;
  border: 1px solid red;
  color: white;
  width: auto !important;
  height: auto !important;
}
.marker {
  height: 50px;
  width: 50px;
  z-index: 999;
}
</style>
