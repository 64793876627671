var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-subheader',{staticClass:"flex"},[_c('div',{staticClass:"pl-2"},[_vm._v("Map Layers")]),(_vm.clickedLayer)?_c('EditMapLayerDialog',{attrs:{"mapLayer":_vm.clickedLayer,"openDialog":_vm.editingMapLayer},on:{"onClose":_vm.onEditMapLayerDialogClose}}):_vm._e(),(_vm.clickedLayer)?_c('DeleteMapLayerDialog',{attrs:{"mapLayer":_vm.clickedLayer,"open-dialog":_vm.deletingMapLayer},on:{"onClose":_vm.onDeleteMapLayerDialogClose}}):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"flat":"","icon":"","small":""},on:{"click":_vm.showAddMapLayerDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Map Layer")])])],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-select',{attrs:{"dense":"","loading":_vm.fetchingMapLayers,"multiple":"","chips":"","label":"Layer List","hide-details":"","items":_vm.groupedMapLayers,"item-text":"name","return-object":"","no-data-text":"No map layers selected"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-badge',{attrs:{"id":((item.fallback ? 'fallback-' : '') + "layer-chip"),"color":"primary","overlap":"","origin":"left"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span')]},proxy:true}],null,true),model:{value:(item.fallback),callback:function ($$v) {_vm.$set(item, "fallback", $$v)},expression:"item.fallback"}},[_c('v-chip',{class:((_vm.selectedChip === index ? 'primary --light' : 'gray --light') + "--text "),attrs:{"color":("" + (_vm.selectedChip === index ? 'primary --light' : 'gray --light'))},on:{"click":function($event){$event.stopPropagation();return _vm.chipClicked(item, index)}}},[_c('span',{staticClass:"pointer"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)]}},{key:"item",fn:function(ref){
                var item = ref.item;
                var on = ref.on;
                var attrs = ref.attrs;
return [(Object.keys(item).includes('isHeading') && item.isHeading)?_c('v-list-tile',_vm._g(_vm._b({},'v-list-tile',attrs,false),on),[_c('v-list-tile-content',[_c('v-list-tile-title',[(_vm.isHeaderOffline(item))?_c('v-divider'):_vm._e(),_c('div',{staticClass:"grey--text font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)],1):_c('v-list-tile',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.mapLayerItemClicked(item)}}},'v-list-tile',attrs,false),on),[_c('v-list-tile-action',[_c('v-checkbox',{attrs:{"color":"primary","input-value":_vm.isLayerSelected(item)}})],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('div',{class:((_vm.isLayerSelected(item) ? 'primary' : 'white') + "--text")},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)]}}]),model:{value:(_vm.selectedMapLayers),callback:function ($$v) {_vm.selectedMapLayers=$$v},expression:"selectedMapLayers"}})],1),_c('v-card-title',{staticClass:"py-0 px-0"},[_c('v-toolbar',{staticClass:"mt-0 px-0",attrs:{"flat":"","height":"50","color":"transparent"}},[(_vm.showLayerDetails)?_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.isAuthorized('site_manager') || _vm.isClickedLayerSeeded)?[_c('v-flex',{staticClass:"shrink align-self-center"},[_c('span',{staticClass:"text-capitalize"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isClickedLayerSeeded)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"yellow","small":""}},'v-icon',attrs,false),on),[_vm._v(" warning ")]):_vm._e()]}}],null,false,573166239)},[_c('span',[_vm._v(" Built-in map layer "),_c('br'),(!_vm.isAuthorized('admin'))?_c('div',[_vm._v(" Cannot be edited/deleted ")]):_vm._e()])]),_vm._v(" Layer Type: "+_vm._s(_vm.selectedLayerType)+" ")],1)]),_c('v-spacer'),(_vm.isAuthorized('site_manager'))?_c('v-flex',{staticClass:"shrink align-self-center"},[_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{staticClass:"mr-0",attrs:{"slot":"activator","icon":"","flat":"","color":"white"},on:{"click":_vm.editSelectedMapLayer},slot:"activator"},[_c('v-icon',[_vm._v("edit")])],1),_c('span',[_vm._v("Modify Map Layer")])],1),_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{staticClass:"mr-0",attrs:{"slot":"activator","icon":"","flat":"","color":"white"},on:{"click":_vm.deleteSelectedMapLayer},slot:"activator"},[_c('v-icon',[_vm._v("delete")])],1),_c('span',[_vm._v("Delete Map Layer")])],1)],1):_vm._e()]:_vm._e()],2):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }