






































































































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { IMapLayer } from './types'
import { FormMixin } from '@/components/Mixins'
import FallbackConfirmDialog from '@/components/Widgets/MapsAndZones/FallbackConfirmDialog.vue'

const props = {
  mapLayer: {
    type: Object,
    required: true
  },
  openDialog: {
    type: Boolean,
    required: true
  }
}

export default Vue.extend({
  name: 'EditMapLayerDialog',
  components: { FallbackConfirmDialog },
  props,
  mixins: [FormMixin],
  data() {
    return {
      showFallbackConfirm: false,
      error: null,
      formValid: false,
      mapLayerClone: null as IMapLayer,
      apiCallInProgress: false as boolean,
      focusedInput: 0
    }
  },
  created() {
    this.mapLayerClone = Object.assign({}, this.mapLayer)
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnterKeyup)
  },
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('maps', ['fallbackMapLayer']),
    isOffline(): boolean {
      return this.mapLayerClone.offline
    },
    inputFieldCount() {
      return this.isOffline ? 2 : 3
    }
  },
  methods: {
    ...mapActions('maps', [
      'updateMapLayer',
      'fetchAllMapLayers',
      'fetchSiteAndLayerMapping',
      'getSelectedMapLayers'
    ]),
    onEnterKeyup(event) {
      if (!this.formValid && event.key === 'Enter') {
        this.focusedInput = (this.focusedInput + 1) % this.inputFieldCount
        this.focusOnInput(this.focusedInput)
      }
      if (this.formValid && event.key === 'Enter') {
        this.onMapLayerEdit()
      }
    },
    focusOnInput(index) {
      setTimeout(() => {
        this.$refs[`input-${index}`].focus()
      }, 250)
    },
    onCancel(): void {
      this.$emit('onClose')
    },
    async onMapLayerEdit(): Promise<void> {
      // check if there's an existing fallback map layer
      if (
        this.fallbackMapLayer &&
        this.fallbackMapLayer?.id !== this.mapLayerClone.id
      ) {
        this.showFallbackConfirm = true
      } else {
        await this.doUpdateMapLayer(this.mapLayerClone)
      }
    },
    async replaceFallbackAndUpdate(): Promise<void> {
      const fallback = Object.assign({}, this.fallbackMapLayer)
      fallback.fallback = false
      await this.doUpdateMapLayer(fallback)
      await this.doUpdateMapLayer(this.mapLayerClone)
      this.showFallbackConfirm = false
      this.$emit('onClose')
    },
    async doUpdateMapLayer(layer: IMapLayer): Promise<void> {
      this.apiCallInProgress = true
      const result = await this.updateMapLayer(layer)
      if (result.success) {
        await this.fetchAllMapLayers()
        await this.fetchSiteAndLayerMapping(this.activeSiteId)
        await this.getSelectedMapLayers()
        this.$emit('onClose')
      } else {
        this.error = result.error
      }
      this.apiCallInProgress = false
    }
  },
  watch: {
    openDialog: {
      handler: function(val: boolean) {
        if (val) {
          this.focusedInput = 0
          this.focusOnInput(this.focusedInput)
        }
      }
    }
  }
})
