



































































































import Vue from 'vue'
import { DialogMixin, FormMixin } from '@/components/Mixins'
import { mapState } from 'vuex'
import { latLngToMgrs } from '@/utils/utils.js'

enum ESVGColours {
  Blue = '#225e97',
  Yellow = '#EEE400',
  Grey = '#ADBCCA'
}

//define props
const props = {
  marker: {
    type: Object
  },
  siteCenter: {
    type: Array,
    default: () => [0, 0]
  },
  header: {
    type: String
  },
  edit: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'MapMarkersDialogForm',
  props,
  mixins: [FormMixin, DialogMixin],

  data: () => ({
    mgrsMask: 'NNNNN NNNNN NNNNN',
    valid: false,
    markerData: {
      id: '',
      lat: '',
      lng: '',
      number: '',
      nickname: ''
    },
    resetMarkerData: {
      id: '',
      lat: '',
      lng: '',
      number: '',
      nickname: ''
    },
    colours: [
      {
        name: 'Grey',
        colour: '#ADBCCA'
      },
      {
        name: 'Yellow',
        colour: '#EEE400'
      },
      {
        name: 'Blue',
        colour: '#225e97'
      }
    ],
    selectedColour: '#225e97'
  }),
  computed: {
    ...mapState(['mgrsEnabled']),
    defaultColour(): string {
      return ESVGColours.Blue
    },
    mgrsLocation(): void {
      return latLngToMgrs(this.marker.lat, this.marker.lng)
    }
  },
  mounted(): void {
    if (!this.edit) {
      Object.assign(this.markerData, this.resetMarkerData)

      if (this.siteCenter) {
        const [lat, lng] = this.siteCenter
        this.marker.lat = lat
        this.marker.lng = lng
      }
    }
  },
  watch: {
    marker(m) {
      if (m.colour) {
        this.selectedColour = m.colour
      } else {
        this.selectedColour = '#225e97'
      }
    }
  },
  methods: {
    submit(): void {
      this.$emit('submit', this.markerData)
      //reset data on submit
      Object.assign(this.markerData, this.resetMarkerData)
    },
    cancel(): void {
      this.$emit('cancel')
      //reset data on cancel
      Object.assign(this.markerData, this.resetMarkerData)
    },
    handleSelectColour(v): void {
      this.marker.colour = v
      this.$bus.$emit('changeMarkerColour', this.marker.colour)
    },
    updateMarkerNumberOnMap(v): void {
      this.$bus.$emit('changeMarkerNumber', v)
    }
  }
})
