































































































































































































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  EMapLayerType,
  IHeadingItem,
  IMapLayer,
  ISiteMapLayerAssociation
} from './types'
import EditMapLayerDialog from '@/components/Widgets/MapsAndZones/EditMapLayerDialog.vue'
import DeleteMapLayerDialog from '@/components/Widgets/MapsAndZones/DeleteMapLayerDialog.vue'

export default Vue.extend({
  name: 'MapLayers',
  components: {
    DeleteMapLayerDialog,
    EditMapLayerDialog
  },
  data() {
    return {
      openDialogEvent: 'map-layer-dialog-open' as string,
      forceOpen: false as boolean,
      selectedLayers: [] as ISiteMapLayerAssociation[],
      clickedLayer: null as IMapLayer | null,
      showAddLayerDialog: false as boolean,
      fetchingMapLayers: false as boolean,
      editingMapLayer: false as boolean,
      deletingMapLayer: false as boolean,
      selectedChip: null as IMapLayer | null
    }
  },
  async mounted() {
    this.$emitter.on('onChangeSite', this.onChangeSite)
    await this.fetchMapLayers(this.activeSiteId)
  },
  beforeDestroy() {
    this.$emitter.off('onChangeSite', this.onChangeSite)
  },
  computed: {
    ...mapState('maps', [
      'allMapLayers',
      'siteMapLayers',
      'siteMapLayerMapping',
      'activeMapLayerId',
      'selectedMapLayers'
    ]),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('users', ['user']),
    showLayerDetails(): boolean {
      return !!this.clickedLayer
    },
    selectedLayerType(): string {
      return this.clickedLayer?.offline
        ? EMapLayerType.OFFLINE
        : EMapLayerType.ONLINE
    },
    groupedMapLayers(): (IHeadingItem | IMapLayer)[] {
      const onlineHeader: IHeadingItem = {
        isHeading: true,
        text: EMapLayerType.ONLINE
      }
      const offlineHeader: IHeadingItem = {
        isHeading: true,
        text: EMapLayerType.OFFLINE
      }
      return [
        onlineHeader,
        ...this.allMapLayers.filter((layer: IMapLayer) => {
          return !layer.offline
        }),
        offlineHeader,
        ...this.allMapLayers.filter((layer: IMapLayer) => {
          return layer.offline
        })
      ]
    },
    isClickedLayerSeeded() {
      if (!this.clickedLayer) {
        return false
      } else {
        return this.clickedLayer?.seeded || false
      }
    }
  },
  methods: {
    ...mapActions('maps', [
      'fetchSiteAndLayerMapping',
      'fetchAllMapLayers',
      'deleteMapLayer',
      'updateMapLayer',
      'addSiteMapLayerAssociation',
      'removeSiteMapLayerAssociation',
      'setActiveMapLayerId',
      'getSelectedMapLayers',
      'showAddMapLayerDialog'
    ]),
    ...mapActions('users', ['UPDATE_USER']),
    async onChangeSite(siteId: number) {
      await this.fetchMapLayers(siteId)
    },
    forceUpdateMap() {
      this.$emitter.emit('invalidateMap')
    },
    async onEditMapLayerDialogClose() {
      this.editingMapLayer = false
      this.clearSelected()
      this.forceUpdateMap()
    },
    async onDeleteMapLayerDialogClose() {
      this.deletingMapLayer = false
      this.clearSelected()
      this.forceUpdateMap()
    },
    isHeaderOffline(item: IHeadingItem): boolean {
      return item.text === EMapLayerType.OFFLINE
    },
    async updateUserProfileMapLayerId(id: number | null) {
      const newUser = Object.assign({}, this.user)
      newUser.settings.mapLayerId = id
      await this.UPDATE_USER(newUser)
    },
    async mapLayerItemClicked(layer: IMapLayer): Promise<void> {
      // find item in array
      try {
        const index = this.selectedMapLayers.findIndex(
          (item: IMapLayer) => item.id === layer.id
        )

        if (index === -1) {
          // associating a layer
          await this.addSiteMapLayerAssociation({
            siteId: this.activeSiteId,
            layerId: layer.id
          })
        } else {
          // disassociating a layer
          await this.removeSiteMapLayerAssociation({
            siteId: this.activeSiteId,
            layerId: layer.id
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        await this.fetchMapLayers(this.activeSiteId)
        if (this.siteMapLayerMapping.length === 0) {
          await this.setActiveMapLayerId(null)
          await this.updateUserProfileMapLayerId(null)
        } else if (this.siteMapLayerMapping.length === 1) {
          await this.setActiveMapLayerId(
            this.siteMapLayerMapping[0].map_layer_id
          )
          await this.updateUserProfileMapLayerId(
            this.siteMapLayerMapping[0].map_layer_id
          )
        }
      }
    },
    isLayerSelected(item: IMapLayer): boolean {
      return Object.values(this.selectedMapLayers)
        .map((layer: IMapLayer) => {
          return layer.id
        })
        .includes(item.id)
    },
    clearSelected(): void {
      this.clickedLayer = null
      this.selectedChip = null
    },
    async onMapLayerUpdate(layer: IMapLayer): Promise<void> {
      await this.updateMapLayer(layer)
      await this.fetchMapLayers(this.activeSiteId)
      this.clearSelected()
    },
    async onMapLayerDelete(layerId: number): Promise<void> {
      const response = await this.deleteMapLayer(layerId)
      await this.fetchMapLayers(this.activeSiteId)

      if (response) {
        if (this.activeMapLayerId === layerId) {
          // deleted the current active layer, switch to first layer in list
          let newActiveLayerId = null
          if (this.siteMapLayerMapping.length > 0) {
            newActiveLayerId = this.siteMapLayerMapping[0].map_layer_id
          }
          await this.setActiveMapLayerId(newActiveLayerId)
          await this.updateUserProfileMapLayerId(newActiveLayerId)
        }
      }
      this.clearSelected()
    },
    chipClicked(item, index): void {
      if (this.selectedChip === index) {
        this.clearSelected()
      } else {
        this.selectedChip = index
        this.clickedLayer = item
      }
    },
    editSelectedMapLayer(): void {
      this.editingMapLayer = true
    },
    deleteSelectedMapLayer(): void {
      this.deletingMapLayer = true
    },
    async fetchMapLayers(siteId: number): Promise<void> {
      await this.fetchAllMapLayers()
      await this.fetchSiteAndLayerMapping(siteId)
      await this.getSelectedMapLayers()
      this.$emitter.emit('refresh-map-layer')
      this.fetchingMapLayers = false
    }
  }
})
