



































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Zones from '@/components/Widgets/Zones.vue'
// @ts-ignore
import ZoneMap from '@/components/Map/ZoneMap'
// @ts-ignore
import Coverage from '@/components/Widgets/Coverage'
import MapMarkersPanel from '@/components/Widgets/CreateMapMarkers/MapMarkersPanel.vue'
// @ts-ignore
import MapLayers from '../components/Widgets/MapsAndZones/MapLayers'
import { IUserSettings } from '@/store/modules/users/types'

export default Vue.extend({
  name: 'ZonesPage',
  data: () => ({
    shape: undefined,
    zoneEditMode: false,
    snackbarTimeout: null,
    settingsUpdateTimer: null,
    selectedZones: []
  }),
  components: {
    Coverage,
    ZoneMap,
    Zones,
    MapMarkersPanel,
    MapLayers
  },
  async mounted(): Promise<void> {
    await this.FETCH_USER()
    this.snackbarTimeout = setTimeout(
      () => this.$bus.$emit('SNACKBAR/MAPS_AND_ZONES'),
      3000
    )
  },
  beforeDestroy(): void {
    if (this.snackbarTimeout) {
      clearTimeout(this.snackbarTimeout)
      this.snackbarTimeout = null
    }
  },
  computed: {
    ...mapState('users', ['user']),
    ...mapState(['radiatingCircle']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sites', ['activeSite', 'siteCenter']),
    ...mapGetters(['displayedSectors']),
    ...mapState('zones', ['visibleZoneType', 'alertZoneFillPattern', 'activeZoneId', 'activeZone']),
    ...mapState(['mgrsEnabled', 'milsEnabled']),
    ...mapState('site_markers', ['showMarkers']),
    userSettings(): IUserSettings {
      return this.user?.settings || {}
    },
    name(): string {
      return this.activeSite && this.activeSite.name
    }
  },
  methods: {
    ...mapActions('users', ['UPDATE_SETTINGS', 'FETCH_USER']),
    zoneEditModeRefresh(zoneEditMode): void {
      this.zoneEditMode = zoneEditMode
    },
    onSelectedZoneUpdate(data): void {
      this.selectedZones = data
      this.updateZoneSettings()
    },
    updateZoneSettings(): void {
      clearTimeout(this.settingsUpdateTimer)
      this.settingsUpdateTimer = setTimeout(async () => {
        const zoneSettings = {
          showMarkers: this.showMarkers,
          visibleZoneTypes: this.visibleZoneType,
          alertZoneFillPattern: this.alertZoneFillPattern,
          displayedSectors: this.displayedSectors,
          mgrsEnabled: this.mgrsEnabled,
          milsEnabled: this.milsEnabled,
          radiatingCircle: this.radiatingCircle
        }

        const updatedSettings = { ...this.user.settings, zoneSettings }
        const updatedUser = { ...this.user, settings: updatedSettings }
        await this.UPDATE_SETTINGS(updatedUser)
      }, 1000)
    },
    updateShape(): void {
      this.shape = this.activeZone && this.activeZone.coordinate_list
    }
  }
})
