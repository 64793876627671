












































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

const props = {
  mapLayer: {
    type: Object,
    required: true
  },
  openDialog: {
    type: Boolean,
    required: true
  }
}

export default Vue.extend({
  name: 'DeleteMapLayerDialog',
  props,
  data() {
    return {
      formValid: false as boolean,
      apiCallInProgress: false as boolean
    }
  },
  computed: {
    ...mapState('maps', ['activeMapLayerId', 'siteMapLayerMapping']),
    ...mapState('sites', ['activeSiteId'])
  },
  methods: {
    ...mapActions('maps', [
      'setActiveMapLayerId',
      'deleteMapLayer',
      'fetchAllMapLayers',
      'fetchSiteAndLayerMapping'
    ]),
    onCancel(): void {
      this.$emit('onClose')
    },
    async onMapLayerDelete(): Promise<void> {
      this.apiCallInProgress = true
      const response = await this.deleteMapLayer(this.mapLayer.id)

      await this.fetchAllMapLayers()
      await this.fetchSiteAndLayerMapping(this.activeSiteId)

      if (response) {
        if (this.activeMapLayerId === this.mapLayer.id) {
          // deleted the current active layer, switch to first layer in list
          if (this.siteMapLayerMapping.length > 0) {
            await this.setActiveMapLayerId(
              this.siteMapLayerMapping[0].map_layer_id
            )
          } else {
            await this.setActiveMapLayerId(null)
          }
        }
      }
      this.onCancel()
      this.apiCallInProgress = false
    }
  }
})
