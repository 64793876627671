



































































































































































import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import L, { Icon } from 'leaflet'
import envelope from '@turf/envelope'
import { featureCollection, point } from '@turf/helpers'

// @ts-ignore
import BaseMap from './BaseMap'
// @ts-ignore
import { EditablePolygon } from 'vue2-leaflet-editable'
import { LPolygon, LFeatureGroup, LMarker, LPopup, LIcon } from 'vue2-leaflet'
// @ts-ignore
import { PolygonFogOfWar } from 'vue2-leaflet-fogofwar'
import { SentryMarker } from './Marker'

import { SentryMixin } from '@/components/Mixins'
// @ts-ignore
import RadiatingCircles from './Element/RadiatingCircles'
import {
  IDisplayedSector,
  TLocationPoint,
  IZone,
  TAllSensorsList,
  TSensorList
} from '@/components/Map/types'
import { ISentry } from '@/store/modules/sentries/types'

// @ts-ignore
const CameraSectorCoverage = () => import('./Coverage/CameraSector')
// @ts-ignore
const CannonSectorCoverage = () => import('./Coverage/CannonSector')
// @ts-ignore
const DiscovairSectorCoverage = () => import('./Coverage/DiscovairSector')
// @ts-ignore
const DronesentryxSectorCoverage = () => import('./Coverage/DronesentryxSector')
// @ts-ignore
const RadarSectorCoverage = () => import('./Coverage/RadarSector')
// @ts-ignore
const RfSectorCoverage = () => import('./Coverage/RFSector')
// @ts-ignore
const IntersectionCoverage = () => import('./Coverage/Intersection')
// @ts-ignore
const MapSiteMarkersLayer = () => import('./MapSiteMarkers/MapSiteMarkersLayer')

const props = {
  center: {
    type: Array,
    default: () => []
  },
  activeZone: {
    type: Object,
    default: () => ({
      id: null,
      coordinate_list: [],
      name: ''
    })
  },
  shape: {
    type: Array
  },
  zoneEditMode: {
    type: Boolean,
    default: false
  },
  zoom: {
    type: Number,
    default: 16
  }
}

export default Vue.extend({
  name: 'ZoneMap',
  mixins: [SentryMixin],
  components: {
    RadiatingCircles,
    BaseMap,
    EditablePolygon,
    SentryMarker,
    LFeatureGroup,
    LMarker,
    PolygonFogOfWar,
    RfSectorCoverage,
    RadarSectorCoverage,
    CannonSectorCoverage,
    CameraSectorCoverage,
    DiscovairSectorCoverage,
    DronesentryxSectorCoverage,
    IntersectionCoverage,
    MapSiteMarkersLayer
  },
  props,
  model: {
    prop: 'shape',
    event: 'change'
  },
  data: () => ({
    name: 'homeMap',
    loading: false,
    attribution: 'DroneShield',
    newShape: null,
    topLeft: {},
    mapCenter: [0, 0],
    bottomRight: {},
    editingNewMap: false,
    activeMap: null
  }),
  beforeDestroy(): void {
    this.$bus.$off('updateEditingNewMap')
    this.$bus.$off('updateActiveMap')
    this.$bus.$off('newMarker')
  },
  async mounted(): Promise<void> {
    this.$bus.$on(
      'updateEditingNewMap',
      editingNewMap => (this.mapEditMode = editingNewMap)
    )
    this.$bus.$on('updateActiveMap', activeMap => (this.activeMap = activeMap))
    this.mapCenter = this.siteCenter
  },
  methods: {
    ...mapActions('site_markers', ['FETCH_SITE_MARKERS']),
    ...mapActions('maps', ['setMapZoom']),
    updateMapZoom(zoom) {
      const {siteZoom} = this;
      this.setMapZoom({zoom, siteZoom})
    },
    isSensorActive(sensor, type): unknown {
      const device = this.activeSensors.find(s => {
        return s.sensor === sensor.id && s.type === type
      })
      return !!device
    },
    getColor(z) {
      return z && z.id === this.activeZone.id ? 'red' : '#FF9800'
    },
    getOpacity(z) {
      return z && z.id === this.activeZone.id ? 0.3 : 0.1
    },
    onMapClick(): void {
      this.$emit('clickMap')
    },
    onShapeChange(e) {
      const {
        target: { _latlngs }
      } = e
      const [coords = []] = _latlngs || []
      this.$emit(
        'change',
        coords.map(e => [e.lat, e.lng])
      )
    },
    dragIcon(position) {
      return L.icon({
        iconUrl: require(`@/assets/drag.` + position + `.png`),
        iconSize: [75, 75],
        iconAnchor: position === 'top.left' ? [25, 25] : [50, 50]
      })
    },
    visibleSectorType(activeSiteId, type) {
      return Object.values(this.displayedSectorsInSite(activeSiteId)).some(
        //@ts-ignore
        v => v.value === type
      )
    },
  },
  computed: {
    ...mapGetters('maps', ['mapZoom']),
    ...mapGetters('sites', ['siteZoom', 'siteCenter', 'activeSiteId']),
    ...mapState('zones', ['visibleZoneType', 'zoneTypeColors']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('zones', ['zonesList']),
    ...mapGetters(['displayedSectorsInSite']),
    ...mapState(['displayedSectors']),
    ...mapState('selection', {
      activeSentries: 'activeSentries',
      activeSensors: 'activeSensors',
      activeGroups: 'activeGroups',
      selectedCamera: 'activeCamera'
    }),
    ...mapState('site_markers', ['site_markers']),
    ...mapGetters('sensors', [
      'allCannons',
      'radarsSet',
      'radarsInSite',
      'discovair_sensorsInSite',
      'cannonsInSite',
      'getOrigin',
      'activeRf_sensor',
      'activeDsx_sensor',
      'activeDiscovair_sensor',
      'activeRadar',
      'activeCannon',
      'activeGps_compass',
      'activeDiscovair_sensorId',
      'highlightedCannon'
    ]),
    ...mapGetters('cameras', ['activeCamera']),
    ...mapGetters('rf_sensors', [
      'rfSensorsInSite',
      'dsxSensorsInSite'
    ]),
    ...mapGetters('cameras', ['cameraTrackedTarget', 'camerasInSite']),
    permanentSectors(): string[] {
      const displayedSectors = this.displayedSectors[this.activeSiteId]
      if (!displayedSectors) return []
      return Object.values(displayedSectors).map(
        (item: IDisplayedSector) => item.value
      )
    },
    sensors(): TAllSensorsList {
      return {
        radars: Object.values(this.radars),
        rfs: Object.values(this.rfsensors),
        cannons: Object.values(this.cannons),
        acoustics: Object.values(this.discovairsensors),
        cameras: Object.values(this.cameras),
        dsx: Object.values(this.dsxsensors)
      }
    },
    radars(): TSensorList {
      return this.radarsInSite(this.activeSiteId, this.sentries)
    },
    cannons(): TSensorList {
      return this.cannonsInSite(this.activeSiteId, this.sentries)
    },
    cameras(): TSensorList {
      return this.camerasInSite(this.activeSiteId, this.sentries)
    },
    rfsensors(): TSensorList {
      return this.rfSensorsInSite(this.activeSiteId, this.sentries)
    },
    dsxsensors(): TSensorList {
      return this.dsxSensorsInSite(this.activeSiteId, this.sentries)
    },
    discovairsensors(): TSensorList {
      return this.discovair_sensorsInSite(this.activeSiteId, this.sentries)
    },
    allZones(): IZone[] {
      return this.activeZone && this.activeZone === 'new'
        ? this.zonesList
        : this.zonesList.concat([{ id: null, name: 'None' }])
    },
    polyMapCoordinates(): TLocationPoint[] {
      let coordinate_list = [
        [this.topLeft.lat || 0, this.topLeft.lng || 0],
        [this.bottomRight.lat || 0, this.bottomRight.lng || 0]
      ].map(([lat, lng]) => point([lng, lat]))
      const polyMap = envelope(featureCollection(coordinate_list))
      const coordinates = polyMap.geometry.coordinates[0]
      return coordinates.map(([lng, lat]) => [lat, lng])
    },
    sentries(): ISentry[] {
      return this.sentriesInSite(this.activeSiteId)
    },
    mapMarkersIcon(): Icon {
      return L.icon({
        iconUrl: require(`@/assets/marker_blank.svg`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    }
  },
  watch: {
    activeMap(v): void {
      if (v && v.id) {
        ;[this.topLeft, this.bottomRight] = v.coordinate_list
      }
    },
    activeSiteId(id): void {
      //make sure map center changes when site changes
      this.mapCenter = this.siteCenter
    },
    topLeft(v): void {
      this.$bus.$emit('activeMapUpdate', [v, this.bottomRight])
    },
    bottomRight(v): void {
      this.$bus.$emit('activeMapUpdate', [this.topLeft, v])
    }
  }
})
