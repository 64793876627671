





































































































































import Vue from 'vue'

import MapMarkersPanelListItem from './Components/MapMarkersPanelListLitem.vue'
import MapMarkersDialogMap from './Components/Dialog/Components/MapMarkersDialogMap.vue'
import MapMarkersDialogForm from './Components/Dialog/Components/MapMarkersDialogForm.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'MapMarkersPanel',
  components: {
    MapMarkersPanelListItem,
    MapMarkersDialogMap,
    MapMarkersDialogForm
  },
  data: () => ({
    markerEditMode: false,
    createDialog: false,
    editDialog: false,
    mapName: 'MapMarkers',
    markerData: {
      id: '',
      number: '',
      nickname: '',
      lat: '',
      lng: '',
      colour: '',
      abbreviation: ''
    },
    resetMarkerData: {
      id: '',
      number: '',
      nickname: '',
      lat: '',
      lng: '',
      colour: '',
      abbreviation: ''
    },
    editMarkerMode: false
  }),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState(['site_markers', 'site_markers']),
    ...mapGetters('sites', ['siteCenter']),
    ...mapState('site_markers', ['showMarkers']),
    ...mapGetters('users', ['isAuthorized']),
    markersArray() {
      return this.site_markers.site_markers
    },
    markersToggle: {
      get(): boolean {
        return this.showMarkers
      },
      set(v): void {
        this.$store.commit('site_markers/TOGGLE_SHOW_SITE_MARKERS', v)
        this.$emit('updated:settings')
      }
    }
  },
  methods: {
    ...mapActions('site_markers', [
      'CREATE_SITE_MARKER',
      'EDIT_SITE_MARKER',
      'REMOVE_SITE_MARKER'
    ]),
    onEditMarker(data) {
      this.markerData = data
      //close dialog
      this.editDialog = true
    },
    onCreateMarker() {
      //close dialog
      Object.assign(this.markerData, this.resetMarkerData)
      this.createDialog = true
      this.marker = {}
    },
    onCancelCreateDialog() {
      this.createDialog = false
    },
    onCancelEditDialog() {
      this.markerData = { ...this.resetMarkerData }
      this.editDialog = false
    },
    onEditMarkerSubmit() {
      this.editDialog = false
      this.EDIT_SITE_MARKER({ siteId: this.activeSiteId, ...this.markerData })
    },
    onCreateMarkerSubmit(v) {
      this.createDialog = false
      this.CREATE_SITE_MARKER({ siteId: this.activeSiteId, ...this.markerData })
    },
    onDeleteMarker(v) {
      this.REMOVE_SITE_MARKER(v)
    },
    updateMarkerLatLng(data) {
      this.$set(this.markerData, 'lat', data[0])
      this.$set(this.markerData, 'lng', data[1])
    }
  },
  watch: {
    createDialog(v) {
      if (v) {
        const [lat, lng] = this.siteCenter
        this.markerData = { ...this.markerData, lat, lng }
      }
    }
  }
})
